<template>
  <div
    class="position-absolute w-100 d-flex justify-content-between align-items-center custom-padding zindex-1 left-0"
  >
    <b-link
      style="width: fit-content"
    >
<!--      <img-->
<!--        class="img-fluid"-->
<!--        style="width:100%; max-width: 180px;"-->
<!--        src="@/assets/images/primary-logo.png"-->
<!--        alt="image"-->
<!--      >-->
    </b-link>
    <ul class="list-style">
<!--      <b-button-->
<!--        v-if="$i18n.locale === 'en'"-->
<!--        v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
<!--        variant="outline-primary"-->
<!--        class="d-flex custom-gap-8"-->
<!--        @click.prevent="setLanguage('ar')"-->
<!--      >-->
<!--        <span>العربية</span>-->
<!--        <b-img-->
<!--          src="@/assets/images/flags/ar.png"-->
<!--          height="14px"-->
<!--          width="22px"-->
<!--          alt="Icon Ar"-->
<!--        />-->
<!--      </b-button>-->
<!--      <b-button-->
<!--        v-if="$i18n.locale === 'ar'"-->
<!--        v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
<!--        variant="outline-primary"-->
<!--        class="d-flex custom-gap-8"-->
<!--        @click.prevent="setLanguage('en')"-->
<!--      >-->
<!--        <span>English</span>-->
<!--        <b-img-->
<!--          src="@/assets/images/flags/en.png"-->
<!--          height="14px"-->
<!--          width="22px"-->
<!--          alt="Icon En"-->
<!--        />-->
<!--      </b-button>-->
    </ul>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import { localize } from 'vee-validate'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import {
  BLink,
  BImg,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  created() {
    this.currentItem()
  },
  methods: {
    setLanguage(lang) {
      lang='ar'
      this.$i18n.locale = lang
      localize(lang)
      if (lang === 'ar') {
        this.direction = 'rtl'
        window.location.reload()
      } else {
        this.direction = 'ltr'
        window.location.reload()
      }
    },
    currentItem() {
      const $theLang = this.direction === 'ltr' ? 'en' : 'ar'
      // localize($theLang)
      localize('ar')

      // eslint-disable-next-line no-return-assign
      return this.$i18n.locale = $theLang
    },
  },
  setup() {
    const { direction } = useAppConfig()
    const currentDirection = computed(() => direction.value)
    return {
      direction,
      currentDirection,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
ul {
  list-style: none;
}
.custom-padding{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-inline-end: 78px;
  padding-inline-start: 20px;
  @media (max-width: 992px) {
    padding: 20px 32px;
  }
}
.custom-gap-8 {
  gap: 8px;
}
</style>
