<template>
  <div class='auth-wrapper auth-v2'>
    <AuthHeader />
    <b-row class='auth-inner m-0 overflow-hidden'>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg='8'
        class='d-none d-lg-flex align-items-center p-0'
      >
        <div class='w-100 d-lg-flex align-items-center justify-content-center'>
          <b-img
            fluid
            :src='imgUrl'
            alt='Login V2'
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg='4'
        class='d-flex align-items-center auth-bg px-2 p-lg-5'
      >
        <b-col
          sm='8'
          md='6'
          lg='12'
          class='px-xl-2 mx-auto'
        >
          <b-card-title
            class='mb-1 font-weight-bold'
            title-tag='h2'
          >
            {{ $t('Welcome to Reinvest') }}! 👋
          </b-card-title>
          <b-card-text class='mb-2'>
            {{ $t('Please sign-in to your account') }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref='loginForm'
            v-slot='{invalid}'
          >
            <b-form
              class='auth-login-form mt-2'
              @submit.prevent='login'
            >
              <!-- email -->
              <b-form-group
                :label="$t('labels.email')"
                label-for='userEmail'
              >
                <validation-provider
                  v-slot='{ errors }'
                  :name="$t('labels.email')"
                  vid='userEmail'
                  rules='required'
                >
                  <b-form-input
                    id='userEmail'
                    ref='userEmail'
                    v-model='userEmail'
                    :state='errors.length > 0 ? false:null'
                    name='userEmail'
                    placeholder='name@example.com'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class='d-flex justify-content-between'>
                  <label for='login-password'>{{ $t('labels.password') }}</label>
                  <!--                  <b-link :to="{name:'reset-password'}">-->
                  <!--                    <small>{{ $t('Forgot Password') }}</small>-->
                  <!--                  </b-link>-->
                </div>
                <validation-provider
                  v-slot='{ errors }'
                  :name="$t('labels.password')"
                  vid='password'
                  rules='required'
                >
                  <b-input-group
                    class='input-group-merge'
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id='login-password'
                      ref='password'
                      v-model='password'
                      :state='errors.length > 0 ? false:null'
                      class='form-control-merge'
                      :type='passwordFieldType'
                      name='login-password'
                      placeholder='******'
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class='cursor-pointer'
                        :icon='passwordToggleIcon'
                        @click='togglePasswordVisibility'
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type='submit'
                variant='primary'
                block
                :disabled='invalid || isLoading'
              >
                <b-spinner
                  v-if='isLoading'
                  small
                  type='grow'
                />
                <span v-else>{{ $t('Sign in') }}</span>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
    <b-modal
      id='modal-blocked'
      ref='modal-blocked'
      ok-only
      ok-variant='danger'
      hide-footer
      modal-class='modal-danger'
      centered
      :title="$t('alert')"
    >
      <div class='d-flex justify-content-center flex-column align-items-center py-2'>
        <img
          src='@/assets/images/blocked.svg'
          alt='Icon'
          class='mb-2'
          style='width: 70px'
        >
        <b-card-text
          class='text-center'
        >
          {{ $t('Your account has been disabled, please see your system administrator') }}
        </b-card-text>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
// eslint-disable-next-line import/extensions
import AuthHeader from '@/components/AuthHeader'
import { fn } from 'moment'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    ripple,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    AuthHeader,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: null,
      userEmail: null,
      sideImg: require('@/assets/images/pages/login-v2.png'),
      isLoading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate()
        .then(success => {
          this.isLoading = true

          if (success) {
            useJwt.login({
              email: this.userEmail,
              password: this.password,
            })
              .then(response => {
                const {
                  permissions,
                } = response.data.data
                useJwt.setToken(response.data.data.token)
                useJwt.setRefreshToken(response.data.data.token)
                const userName = response.data.data.name_label
                const role_id = response.data?.data?.roles[0]['id']
                const userData = {
                  email: response.data?.data?.email,
                  phone: response.data?.data?.phone,
                  username: response.data?.data?.username,
                  role_id: response.data?.data?.roles[0]['id'],
                }
                const user = {
                  userName,
                  userData,
                  role_id,
                  ability: [{
                    action: 'manage',
                    subject: 'all',
                  }],
                }
                localStorage.setItem('userData', JSON.stringify(user))
                let itemsForPermission = response.data.data.permissions
                const filteredPermissions = {}
                itemsForPermission = Object.keys(itemsForPermission).forEach(key => {
                  if (itemsForPermission[key].can_view === true) {
                    filteredPermissions[key] = itemsForPermission[key]
                  }
                })

                localStorage.setItem('permissions', JSON.stringify(filteredPermissions))
                this.$ability.update(user.ability)

                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                this.$router.replace(getHomeRouteForLoggedInUser('admin'))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: this.$t('welcome') + ` ${response.data.data.name_label}`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: this.$t('you_have_successfully_logged_in'),
                      },
                    })
                  })

                this.isLoading = false

              })
              .catch(error => {
                if (error.response && error.response.status === 400) {
                  this.$refs['modal-blocked'].show()
                  setTimeout(() => {
                    this.userEmail = null
                    this.password = null
                    this.$refs.loginForm.reset()
                    this.$refs.loginForm.setErrors({})
                    this.$refs.loginForm.errors = {
                      password: [],
                      userEmail: [],
                    }
                  }, 200)
                } else {
                  this.$refs['modal-blocked'].hide()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-start',
                    props: {
                      title: this.$t('Error'),
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: error.response.data.message,
                    },
                  })
                }

                this.isLoading = false

              })
          } else {
            this.isLoading = false

          }
        })
    },
  },
}
</script>

<style lang='scss'>
@import '@core/scss/vue/pages/page-auth.scss';
</style>
